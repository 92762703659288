<template>
  <section v-if="!slice.primary.hide_section" class="home__small-callout">
    <div class="row">
      <div class="column small-12 xlarge-4 xxlarge-3">
        <PrismicLazyImage
          :image="slice.primary.image"
          width="950"
          height="600"
        />
      </div>
      <div class="column small-12 large-8 xlarge-5 xxlarge-6">
        <h2 v-if="slice.primary.title">
          <prismic-rich-text :field="slice.primary.title" />
        </h2>
        <prismic-rich-text
          v-if="slice.primary.description"
          :field="slice.primary.description"
        />
      </div>
      <div class="column small-12 large-4 xlarge-3">
        <Button :link="slice.primary.button_link" :text="slice.primary.button_text" />
      </div>
    </div>
  </section>
</template>

<script>
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import Button from '@/components/global/Button';

  export default {
    name: 'HomepageSmallCallout',

    components: {
      PrismicLazyImage,
      Button
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    }
  }
</script>
